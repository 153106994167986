.ql-video {
    width: 100%;
    height: 400px;
}

.ql-snow .ql-tooltip {
    left: 0px !important;
    top: -51px !important;
}

.quill.quill.ql-error {
    .ql-toolbar {
      border-bottom: 1px solid red;
    }
    .ql-container {
        border-top: 1px solid red;
      border-left: 1px solid red;
      border-right: 1px solid red;
      border-bottom: 1px solid red;
    }
  }